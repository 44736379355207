import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import Stodlinjen from '../images/stodlinjen.svg';

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginRight: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  feature: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'end',
    padding: '2px 0',
  },
  featureIcon: {
    color: theme.palette.secondary.main,
    marginRight: 4,
  },
  chip: {
    // marginRight: theme.spacing.unit,
    height: 'auto',
    padding: '2px 0px',
    margin: '4px 8px 2px 0',
  },
}));

function BonusInfo({ bonus }) {
  const classes = useStyles();
  if (bonus) {
    const {
      money,
      deposit,
      odds,
      turnover,
      days,
      extra,
      link,
      instructions,
    } = bonus;
    const percentage = (parseInt(money) / parseInt(deposit)) * 100;
    const isSpecial = isNaN(percentage) || isNaN(money);
    return (
      <>
        <Typography variant="h5" component="h2" gutterBottom>
          {isSpecial ? money : `${percentage}% upp till ${money} kr`}
        </Typography>
        {extra && (
          <Typography
            variant="subtitle1"
            style={{ marginTop: -12 }}
            gutterBottom
          >
            <b>{extra}</b>
          </Typography>
        )}
        {isSpecial ? (
          <Typography variant="body2">
            {instructions.substring(0, 120) + '...'}
          </Typography>
        ) : (
          <>
            <Typography variant="body2">
              Sätt in <b>{deposit} kr</b>, spela för{' '}
              <b>{parseInt(deposit) + parseInt(money)} kr</b>
            </Typography>
            <Typography variant="body2">
              Oms.krav: <b>{turnover}X</b> bonusbeloppet, minsta odds för att
              omsättningen skall gälla: <b>{odds}</b> inom <b>{days} dagar</b>.
            </Typography>
            Spela ansvarsfullt.
            <Chip className={classes.chip} label="Svensk licens" />
            <Chip className={classes.chip} label="18+" />
            <br />
            <Typography variant="body2">
              <Link href={link} target="_blank" rel="noopener noreferrer">
                Läs fullständiga regler och villkor här.
              </Link>
              <a
                href="https://stodlinjen.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Stodlinjen height="20 " />
              </a>{' '}
              <Link
                href="https://www.spelpaus.se"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.spelpaus.se
              </Link>
            </Typography>
          </>
        )}
      </>
    );
  }
  return <Typography variant="body1">Ingen info om bonus.</Typography>;
}

export default BonusInfo;
