import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Img from 'gatsby-image';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Stodlinjen from '../images/stodlinjen.svg';
import YearLimit from '../images/plus18.svg';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 32,
    color: 'white',
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(6, 4),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(10, 6),
    },
  },
  body: {
    zIndex: 1,
    position: 'relative',
    fontSize: 24,
    maxWidth: 640,
  },
  title: {
    fontSize: 32,
    marginBottom: 18,
    fontWeight: 'bold',
  },
  paragraph: {
    fontSize: 24,
  },
  bgImg: {
    position: 'absolute !important',
    top: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    pointerEvents: 'none',
  },
  yearLimit: {
    marginRight: 8,
  },
}));

function Hero({ children, title, content, image }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.body}>
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <Box display="flex" style={{ marginTop: 10 }}>
          <Typography className={classes.paragraph}>
            {content}
            <br />
            <YearLimit height="35" className={classes.yearLimit} />{' '}
            <a
              href="https://stodlinjen.se"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Stodlinjen height="40" />
            </a>
            <br />
          </Typography>
        </Box>
        {/* <br />
        <Button variant="contained" color="primary" size="large">
          Registrera gratis
        </Button> */}
      </div>
      <Img
        fluid={
          image && image.childImageSharp ? image.childImageSharp.fluid : {}
        }
        className={classes.bgImg}
      />
    </Paper>
  );
}
export default Hero;
